.btnSmallWide {
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vh;
    color: #d2d6e8;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.5vw;
    padding: 0.3vw 0vw .5vw 0vw;
    margin: 2vh auto;
    display: flex;
    flex-direction: column;
    border: none;
    align-items: center;
    text-align: center;
    width: 28vw;
    background: #009f93;
    height: 4.2vh;
	
    right: 12.5vw;
    z-index: 1000;
}

.homeContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 4vh;
    bottom: 5vh;
    right: 10vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #D2D6E8;
    background: #3A4456;
    cursor: pointer;
    border-radius: .75vw;
    border: 0vw;
    text-align: center;
    width: 40vw;
    height: 92vh;
}

.pocBanner1 {
    padding: 1vw 0vw 0vw 0vw;
    background-color: #0098C6;
    border-radius: 0vw 0vw 0vw 0vw;
    height: 5.5vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    font-weight: bold;
    text-align: center;
    color: #d2d6e8;
}

.pocBanner {
    padding: 1vw 0vw 0vw 0vw;
    background-color: #0098C6;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 5.5vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    font-weight: bold;
    text-align: center;
    color: #d2d6e8;
}
