html {
    display: block;
    /*position: absolute;*/
}

body {
    margin: 0;
    display: flex;
    /*position: relative;*/
}

div {
    display: block;
}

#root {
    /*margin: 0;*/
}

.absolute {
    /*position: fixed;*/
    z-index: 500;
}

/**
 *
 *
 *
 *
 *
 */

.App {
    display: flex;
    position: absolute;
    text-align: center;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
}

.App-logo {
  /*animation: App-logo-spin infinite 2s linear;*/
  height: 100px;

}

.dev-buttons {
    flex-direction: column;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 10px;
  color: white;
}

.App-header-hidden {
  display: none;
  visibility: hidden;
}

.hidden {
  display: none;
  visibility: hidden;
}

.App-title {
  font-size: 1.1em;
}

.App-intro {
  font-size: large;
}

.App-scene {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1000;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.sumerian-canvas-inner-container {
  position: relative;
  overflow: hidden;
}

.sumerian {
  position: absolute;
}

.dev-buttons {
    left: 10vw;
    top: 20vh;
    width: 15vw;
    height: 10vh;
    font-size: 16px;
   justify-content: space-between;
}

.vertical-container {
    position: absolute;
    top: 20vh;
    height: 20vh;
    max-height: 50vh;
    width: 10vw;
    left: 0.5vw;
    bottom: 9.5vh;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    justify-content: space-between;
    align-content: space-between;
}

.vertical-item {
    /*flex: flex-shrink;*/
    font-size: 1em;
    width: 8vw;
    margin-top: auto;
    margin-bottom: auto;
}

.Video-Container {
  display: flex;
  position: absolute;
  text-align: center;
  height: 100vh;
  width: 100vw;
  justify-content: center;
}

.videoContainer_FullScreen {
    
}

video#splash-container {
  height: 100vh;
  width: auto;
  justify-content: center;
}

#splash-container {
  justify-content: center;
  display: flex;
  margin: auto;
}

#summary-container {
    z-index: 100;
}

#summary-diagram-container {
    z-index: 100;
    position: absolute;
}

#toggle-summary {
    position: absolute;
    z-index: 100;
    /*display: inline-block;*/
    top: 94.5vh;
    left: 93.5vw;
}

.inline-button {
    position: absolute;
    display: inline-block;
    z-index: 100;
    left: 2vw;
}

#containerDiv {
    position: absolute;
    z-index: 200;
}

.react-board {
    width: 60vw;
    height: 60vh;
    top: 25vh;
    left: 30vw;
    z-index: 5000000;
}

.react-board-row {
    width: 50vw;
    height: 8vh;
    display: flex;
    flex-direction: row;
    z-index: 5000000;
}

.react-board-cell {
    width: 6.75vw;
    height: 6.75vh;
    margin: 0;
    z-index: 5000000;
}

.whiteCell {
    background-color: white;
}

.blackCell {
    background-color: black;
}

/**
 *
 *
 * ************** diagram section **************
 *
 *
 */




.utterance-caption {
    font-size: .85em;
    padding-left: 6vw;
    margin-top: auto;
    margin-bottom: auto;
}

.summary-header-text {
    font-size: 5vh;
    padding-right: 5.5vw;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: auto;
    padding-bottom: auto;
}

.progress-bar {
    width: 25vw;
}

#summary-header-achievement {
    padding-right: 2.5vw;
    padding-top: auto;
    padding-bottom: auto;
    height: 100%;
}

#achievement-header {
    padding-top:0.6vh;
}

#achievement-captions {
    display: flex;
    flex-wrap: nowrap;
    align-content: flex-end;
    padding-left: 19vw;
    padding-bottom: 0vh;
    padding-top: 0vh;
    margin-bottom: 1vh;
    margin-top: 0;
    font-size: 2vh;
    height: 1.5vh;
}

#hr-utterance {
    padding-left: 4.5vw;
}

#oxygen-utterance {
    padding-left: 4.2vw;
}

#bp-utterance {
    padding-left: 3vw;
}

#weight-utterance {
    padding-left: 6vw;
}

#temperature-utterance {
    padding-left: 4vw;
}

#achievement-score {
    margin-left: auto;
    padding-right: 4vw;
}



/* --------------- ELEMENT STYLING --------------- */
button {
    display: block;
}

.ui-button {
    position: absolute;
    z-index: 100;
}


.icon,
.text {
    display: flex;
    flex-wrap: wrap;
    align-items: center
}

.btn-ecg {
    /*position: absolute;*/
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #02645E;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    top: 20vh;
    align-items: center;
    text-align: center;
    width: 15vw;
}

.btn-ecg.vertical-item {
    font-size: 1em;
    height: 4vh;
    width: 6vw;
    align-items: center;
    justify-content: center;
    align-content: center;
    /*justify-content: center;*/
}

.btnNo {
    margin: 0.9em;
    font-family: "Open Sans", sans-serif;
    font-size: 6vw;
    color: #EEF0F6;
    background: #B4443E;
    cursor: pointer;
    border-radius: .4vw;
    padding-bottom: 0.1vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 30vw;
}

#goto-vitals {
    font-size: 1em;
}

.vitalsTutorialImage {
    position: absolute;
    top: 0vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;


/*    top: 20vw;
    bottom: 20vw;
    right: 10vw;
    width: 40vw;
    height: 60vh;*/
    margin: auto;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btnNo:active,

/* What happens once selected */
.btnNo:focus {
    animation: borderPulseNo 1000ms infinite ease-out;
    animation: borderPulseNo 1s;
    background-color: #B4443E;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}


/* --------------- Animation --------------- */

/* Declare border pulse animation */
@keyframes borderPulseNo {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(180, 68, 62, .4), 0em 0em 0em 0em rgba(180, 68, 62, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(180, 68, 62, .2), 0em 0em 0em .4em rgba(180, 68, 62, 0);
    }
}


/* Gradient properties */
button.btnNo {
    background-position: center;
    transition: background .8s;
}



/**
 *
 *
 *
 */


#gradient {
    width: 100vw;
    height: 10vh;
    top: 90vh;

    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

/**
 *
 *
 *
 *
 */



.st1 {
    fill: #d2d6e8;
}





/**
 *
 *
 *
 *
 *
 *
 *
 */



.btndone {
    font-family: "Open Sans", sans-serif;
    font-size: 5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #441155;
    cursor: pointer;
    border-radius: 1vw;
    padding-bottom: 1vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 30vw;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btndone:active,

/* What happens once selected */
.btndone:focus {
    animation: borderPulseDone 1000ms infinite ease-out;
    animation: borderPulseDone 1s;
    background-color: #BB84D0;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btndone:focus .white {
    fill: #ffffff;
}

.btndone:focus .purple {
    fill: #BB84D0
}


/* --------------- Animation --------------- */

/* Declare border pulse animation */
@keyframes borderPulseDone {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(187, 132, 208, .4), 0em 0em 0em 0em rgb(187, 132, 208, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(187, 132, 208, .2), 0em 0em 0em .4em rgb(187, 132, 208, 0);
    }
}


/**
 *
 *
 *
 *
 *
 *
 *
 */



.btnhelp {
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #02645E;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 15vw;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btnhelp:active,

/* What happens once selected */
.btnhelp:focus {
    animation: borderPulseHelp 1000ms infinite ease-out;
    animation: borderPulseHelp 1s;
    background-color: #49C0AD;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btnhelp:focus .white {
    fill: #fff;
}

.btnhelp:focus .green {
    fill: #49C0AD;
}

/* Gradient properties */
button.btnhelp {
    background-position: center;
    transition: background .8s;
}


/**
 *
 *
 *
 *
 *
 *
 *
 */




.btnnotyet {
    font-family: "Open Sans", sans-serif;
    font-size: 5vw;
    font-weight: bold;
    color: #d2d6e8;
    background: #B4443E;
    cursor: pointer;
    border-radius: 1vw;
    padding-bottom: 1vw;
    padding-left: 1vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 30vw;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btnnotyet:active,

/* What happens once selected */
.btnnotyet:focus {
    animation: borderPulseNotYet 1000ms infinite ease-out;
    animation: borderPulseNotYet 1s;
    background-color: rgba(180, 68, 62, 0);
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btnnotyet:focus .st3 {
    fill: #fff;
}


/* Gradient properties */
button.btnnotyet {
    background-position: center;
    transition: background .8s;
}


/**
 *
 *
 *
 *
 *
 *
 *
 */



.btnyes {
    font-family: "Open Sans", sans-serif;
    font-size: 5vw;
    font-weight: bold;
    color: #d2d6e8;
    background: #00B64A;
    cursor: pointer;
    border-radius: 1vw;
    padding-bottom: 1vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 30vw;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btnyes:active,

/* What happens once selected */
.btnyes:focus {
    animation: borderPulseYes 1000ms infinite ease-out;
    animation: borderPulseYes 1s;
    background: rgba(0, 182, 74, 0);
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btnyes:focus .st0 {
    fill: rgba(0, 182, 74, 0);
    transition: fill .5s ease-in;
}


/* Gradient properties */
button.btnyes {
    background-position: center;
    transition: background .8s;
}


.container {
    max-width: 90%;
}

.progress-segment {
    display: flex;
    margin: 2vw 1vw 2vw 1vw;
}

.item {
    width: 100%;
    background-color: #D2D6E8;
    margin-right: 0.3vw;
    height: 3vw;
    border-radius: 0.3vw;
}

.item:first-child {
    border-top-left-radius: 1vw;
    border-bottom-left-radius: 0.3vw;
}

.item:last-child {
    border-top-right-radius: 0.3vw;
    border-bottom-right-radius: 1vw;
}

.item.segOne {
    background: #045c84 !important;
}

.item.segTwo {
    background: #13668e !important;
}

.item.segThree {
    background: #23769d !important;
}

.item.segFour {
    background: #328bb1 !important;
}

.item.segFive {
    background: #3fa1c7 !important;
}

.item.segSix {
    background: #4ab6dd !important;
}

.item.segSeven {
    background: #4BCBF3 !important;
}


/**
 *
 *
 *
 *
 *
 *
 *
 */



/*----- Rapid Response -----*/
.btnRR {
    margin-top: 5vh;
    margin-left: 5vw;
    margin-bottom: 1vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #009F93;
    cursor: pointer;
    border-radius: .5vw;
    padding-top: .75vw;
    padding-bottom: 1.1vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 25vw;
}

.btnRR:active,
.btnRR:focus {
    animation: borderPulseRR 1000ms infinite ease-out;
    animation: borderPulseRR 1s;
    background-color: #49C0AD;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@keyframes borderPulseRR {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}


/*----- Customer Support -----*/
.btnCS {
    margin-top: 4.75vh;
    margin-left: 5vw;
    margin-bottom: 1vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #009F93;
    cursor: pointer;
    border-radius: .5vw;
    padding-top: .75vw;
    padding-bottom: 1.1vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 25vw;
}

.btnCS:active,
.btnCS:focus {
    animation: borderPulseCS 1000ms infinite ease-out;
    animation: borderPulseCS 1s;
    background-color: #49C0AD;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@keyframes borderPulseCS {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}


/*----- Pocket MD -----*/
.btnPMD {
    margin-top: 4.75vh;
    margin-left: 5vw;
    margin-bottom: 1vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #009F93;
    cursor: pointer;
    border-radius: .5vw;
    padding-top: .75vw;
    padding-bottom: 1.1vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 25vw;
}

.btnPMD:active,
.btnPMD:focus {
    animation: borderPulsePMD 1000ms infinite ease-out;
    animation: borderPulsePMD 1s;
    background-color: #49C0AD;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@keyframes borderPulsePMD {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
    animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    background-position: center;
    transition: background .8s;
}

/* Gradient properties */
button.btndone {
    background-position: center;
    transition: background .8s;
}


/**
 *
 *
 *
 *
 *
 *
 *
 */


h1 {
    font-family: "Open Sans", sans-serif;
    font-size: 4vw;
    text-align: center;
    color: #EEF0F6;
    font-weight: normal;
}

.med-table {
    background: #DAE3EB;
    align-object: center;
    border-radius: 1vw;
    overflow: hidden;
}

tr:nth-child(odd) td {
    background: #D2D6E8;
}

table,
th {
    border: 0px;
    color: #EEF0F6;
    background: #045C84;
    text-align: center;
    font-size: 1.7vw;
    padding: 2vw 4vw 2vw 4vw;
    font-family: "Open Sans", sans-serif;
    table-border: 0; 
    border-collapse: collapse;
    cellspacing: 0;
}

table,
td {
    color: #000;
    text-align: center;
    font-size: 1.2vw;
    padding: 2vw;
    font-family: "Open Sans", sans-serif;
}


.bakground {

    padding-top: 15em;
    padding-bottom: 42em;
    padding-left: 18em;
    padding-right: 31em;
    margin-top: 1em;
    background-color: white;
}





/*************************/
/*      BTN_Addison      */
/*************************/

#btnaddison {
    background: #094074;
    top: 1vw; 
    left: 17vw;
    z-index: 10;
}

#btnaddison:focus {
    animation: borderPulseAddison 1000ms infinite ease-out;
    animation: borderPulseAddison 1s;
    background-color: #4BCBF3;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btnaddison:focus .a {
    fill: #4BCBF3;
}

#btnaddison:focus .background {
    fill: #fff;
}

@keyframes borderPulseAddison {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(75, 203, 243, .4), 0em 0em 0em 0em rgb(75, 203, 243, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(75, 203, 243, .2), 0em 0em 0em .4em rgb(75, 203, 243, 0);
    }
}

.btnaddison {
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    font-weight: bold;
    color: #d2d6e8;
    background: #094074;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    width: 15vw;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btnaddison:active,

/* What happens once selected */
.btnaddison:focus {
    animation: borderPulseAddison 1000ms infinite ease-out;
    animation: borderPulseAddison 1s;
    background-color: #4BCBF3;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btnaddison:focus .a {
    fill: #4BCBF3;
}

.btnaddison:focus .background {
    fill: #fff;
}


/*******************
* Summary Buttons *
*******************/

/* Oxygen */

#oxygen-levels-summary {
    background-color: #00B54A;
    color: #EEF0F6;

}

.st0 {
    fill: #FF001E;
}

.st1 {
    fill: #FFFFFF;
}

.st2 {
    fill: #FAFAFA;
}

.st3 {
    fill: #434343;
}

.st4 {
    fill: #8DA9C4;
}

.st5 {
    fill: #3A4456;
}

.st6 {
    fill: none;
    stroke: #8DA9C4;
    strokeWidth: 3;
    strokeLinecap: round;
    strokeMiterlimit: 10;
}

.st7 {
    fill: #43535C;
}

.st8 {
    opacity: 0.5;
}

.st9 {
    opacity: 0.5;
    enableBackground: new;
}

#hr-chart canvas {
    display: "block"
}

#oxygen-value {}

#blood-pressure-summary {
    color: #EEF0F6;
    background-color: #B51E2D;

}

#bp-value {}

#weight-summary {
    color: #EEF0F6;
    background-color: #00B54A;
    /*  background-color: rgba(238, 27, 35, .75); */

}

#temperature-summary {
    color: #EEF0F6;
    background-color: #00B54A;

}

.icon-svg {
    float: left;
    padding: 0% 1vw 0% 1vw;
    margin-top: auto;
    margin-bottom: auto;
}

.vital-name {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: .5vw;
    font-size: 1.25em;
}

.vitals-text {
    padding-right: 5%;
}

.summary-back-button {
    display: flex;
    font-family: "Open Sans", sans-serif;
    font-size: 18pt;
    cursor: pointer;
    color: #EEF0F6;
    background-color: #00B54A;
    font-weight: bold;
    justify-content: center;
    background: rgba(11, 37, 69, .85);
    border: 0;

}

.summary-back-button:hover {
    background-color: green;
}

#hr-levels-summary {

    background-color: #00B54A;
    color: #EEF0F6;
}

.green-label {
    background-color: #00b64a;
    height: 30px;
    width: 60px;
    text-align: center;
}

.yellow-label {
    background-color: #ffbb33;
    height: 30px;
    width: 60px;
    text-align: center;
}

.red-label {
    background-color: #b4443e;
    height: 30px;
    width: 50px;
    text-align: center;
}

.label-text {
    margin-left: 1vw;
    font-size: 14pt;
    color: #EEF0F6;
    align-content: center;
}

.two-item-row {
    width: 10vw;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.chart-legend {
    margin-top: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 16pt;
}


/*************************/
/*    Html_Meditation    */
/*************************/

#medVideo {
    position: fixed;
    height: 100vh;
    right: 0vw;
}


/*************************/
/*   BTN_IFeelBetter     */
/*************************/

#btnBetter {
    top: 32vw;
    background: #00B64A;
}

#btnBetter:focus {
    animation: borderPulseYes 1000ms infinite ease-out;
    animation: borderPulseYes 1s;
    background: rgba(0, 182, 74, 1);
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btnBetter:focus .checkMark {
    fill: #fff;
}

@keyframes borderPulseYes {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 182, 74, .4), 0em 0em 0em 0em rgba(0, 182, 74, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 182, 74, .2), 0em 0em 0em .4em rgba(0, 182, 74, 0);
    }
}

.btnSmallSierra {
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    font-weight: bold;
    color: #d2d6e8;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
 width: 12vw;
    position: fixed;
    /* right: 12.5vw; */
    right: 86.5vw;
    top: 80vh;

    background-position: center;
    transition: background .8s;

}

/*************************/
/*     BTN_Nutrition     */
/*************************/

.btnNutrition {
    top: 45.5vw;
    background:#009F93;
}

.btnNutrition:focus {
    animation: borderPulseDone 1000ms infinite ease-out;
    animation: borderPulseDone 1s;
    background-color: #009F93;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btnNutrition:focus .white {
    fill: #ffffff;
}

.btnNutrition:focus .primaryGreen {
    fill: #009F93;
}

@keyframes borderPulseNutrition {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 159, 147, .4), 0em 0em 0em 0em rgba(0, 159, 147, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 159, 147, .2), 0em 0em 0em .4em rgba(0, 159, 147, 0);
    }
}


/*************************/
/*   BTN_INEEDMOREHELP   */
/*************************/

#btnNeedHelp {
    top: 42.5vw;
    background: #B4443E;
    padding-left: 1.5vw;
}

#btnNeedHelp:focus {
    animation: borderPulseNotYet 1000ms infinite ease-out;
    animation: borderPulseNotYet 1s;
    background-color: rgba(180, 68, 62, 0);
    transition: background 1s;
    outline: 0em;
    color: #fff;
}

#btnNeedHelp:focus .notYetX {
    fill: #fff;
}

@keyframes borderPulseNotYet {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(180, 68, 62, .4), 0em 0em 0em 0em rgb(180, 68, 62, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(180, 68, 62, .2), 0em 0em 0em .4em rgb(180, 68, 62, 0);
    }
}


/*************************/
/*      BTN_Stressed     */
/*************************/

#stressed {
    top: 32vw;
    background: #016E75;
}

#stressed:focus {
    animation: borderPulseYes 1000ms infinite ease-out;
    animation: borderPulseYes 1s;
    background: rgba(1, 110, 117, 1);
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#stressed:focus .checkMark {
    fill: #fff;
}

@keyframes borderPulseYes {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 182, 74, .4), 0em 0em 0em 0em rgba(0, 182, 74, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 182, 74, .2), 0em 0em 0em .4em rgba(0, 182, 74, 0);
    }
}


/*************************/
/*  Login Form           */
/*************************/
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="username"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.h3 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.2;
    color: inherit;
}

label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

.signin-btn{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    display: block;
    width: 100%;
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
}



































































































/*************************/
/*    Generic Buttons    */
/*************************/

.btnSmall {
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    font-weight: bold;
    color: #d2d6e8;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    width: 15vw;
    position: absolute;
    z-index: 5;

    background-position: center;
    transition: background .8s;
}

.btnMedium {
    margin-top: 5vh;
    margin-left: 5vw;
    margin-bottom: 1vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #009F93;
    cursor: pointer;
    border-radius: .5vw;
    padding-top: .75vw;
    padding-bottom: 1.1vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 25vw;
    z-index: 5;

    background-position: center;
    transition: background .8s;
}

.btnMedium:focus {
    animation: borderPulseMedium 1000ms infinite ease-out;
    animation: borderPulseMedium 1s;
    background-color: #49C0AD;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@keyframes borderPulseMedium {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}

@keyframes borderPulseHelp {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}



.btnLarge {
    font-family: "Open Sans", sans-serif;
    font-size: 5vw;
    color: #d2d6e8;
    font-weight: bold;
    cursor: pointer;
    border-radius: 1vw;
    padding-bottom: 1vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 30vw;
    background: b00b00;
    position: fixed;
    right: 12.5vw;
    z-index: 5;

    background-position: center;
    transition: background .8s;
}


.btnTall {
    margin-top: 5vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #0098C6;
    cursor: pointer;
    border-radius: .5vw;
    padding-top: .75vw;
    padding-bottom: 1.1vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 13vw;
    height: 8vw;
    z-index: 5;
    background-position: center;
    transition: background .8s;
}

.btnTall:focus {
    animation: borderPulseTall 1000ms infinite ease-out;
    animation: borderPulseTall 1s;
    background-color: #4BCBF3;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@keyframes borderPulseTall {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(75, 203, 243, .4), 0em 0em 0em 0em rgb(75, 203, 243, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(75, 203, 243, .2), 0em 0em 0em .4em rgb(75, 203, 243, 0);
    }
}



.left-button {
    left: 1vw;
}


/*************************/
/*        BTN_Done       */
/*************************/

#btndone {
    top: 42.5vw;
    background: #441155;
    left: 57vw;
    position: absolute;
}

#btndone:focus {
    animation: borderPulseDone 1000ms infinite ease-out;
    animation: borderPulseDone 1s;
    background-color: #BB84D0;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btndone:focus .white {
    fill: #ffffff;
}

#btndone:focus .purple {
    fill: #BB84D0
}

@keyframes borderPulseDone {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(187, 132, 208, .4), 0em 0em 0em 0em rgb(187, 132, 208, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(187, 132, 208, .2), 0em 0em 0em .4em rgb(187, 132, 208, 0);
    }
}


/*************************/
/*        BTN_Ready      */
/*************************/

#btnready {
    top: 42.5vw;
    background: #441155;
}

#btnready:focus {
    animation: borderPulseDone 1000ms infinite ease-out;
    animation: borderPulseDone 1s;
    background-color: #BB84D0;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btnready:focus .white {
    fill: #ffffff;
}

#btnready:focus .purple {
    fill: #BB84D0
}

@keyframes borderPulseDone {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(187, 132, 208, .4), 0em 0em 0em 0em rgb(187, 132, 208, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(187, 132, 208, .2), 0em 0em 0em .4em rgb(187, 132, 208, 0);
    }
}


/*************************/
/*        BTN_Help       */
/*************************/

#btnhelp {
    background: #02645E;
    top: 1vw;
}

#btnhelp:focus {
    animation: borderPulseHelp 1000ms infinite ease-out;
    animation: borderPulseHelp 1s;
    background-color: #49C0AD;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btnhelp:focus .white {
    fill: #fff;
}

#btnhelp:focus .green {
    fill: #49C0AD;
}

@keyframes borderPulseHelp {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(73, 192, 173, .4), 0em 0em 0em 0em rgba(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(73, 192, 173, .2), 0em 0em 0em .4em rgba(73, 192, 173, 0);
    }
}


/*************************/
/*       BTN_NotYet      */
/*************************/

#btnnotyet {
    top: 42.5vw;
    background: #B4443E;
    padding-left: 1.5vw;
    position: absolute;
    right: 12.5vw;
}

#btnnotyet:focus {
    animation: borderPulseNotYet 1000ms infinite ease-out;
    animation: borderPulseNotYet 1s;
    background-color: rgba(180, 68, 62, 0);
    transition: background 1s;
    outline: 0em;
    color: #fff;
}

#btnnotyet:focus .st3 {
    fill: #fff;
}

@keyframes borderPulseNotYet {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(180, 68, 62, .4), 0em 0em 0em 0em rgb(180, 68, 62, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(180, 68, 62, .2), 0em 0em 0em .4em rgb(180, 68, 62, 0);
    }
}


/*************************/
/*         BTN_No        */
/*************************/

#btnno {
    top: 42.5vw;
    background: #B4443E;
    padding-left: 1.5vw;
}

#btnno:focus {
    animation: borderPulseNo 1000ms infinite ease-out;
    animation: borderPulseNo 1s;
    background-color: rgba(180, 68, 62, 0);
    transition: background 1s;
    outline: 0em;
    color: #fff;
}

#btnno:focus .st3 {
    fill: #fff;
}

@keyframes borderPulseNo {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(180, 68, 62, .4), 0em 0em 0em 0em rgb(180, 68, 62, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(180, 68, 62, .2), 0em 0em 0em .4em rgb(180, 68, 62, 0);
    }
}



/*************************/
/*        BTN_Yes        */
/*************************/

#btnyes {
    top: 32vw;
    background: #00B64A;
}

#btnyes:focus {
    animation: borderPulseYes 1000ms infinite ease-out;
    animation: borderPulseYes 1s;
    background: rgba(0, 182, 74, 1);
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btnyes:focus .checkMark {
    fill: #fff;
}

@keyframes borderPulseYes {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 182, 74, .4), 0em 0em 0em 0em rgba(0, 182, 74, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 182, 74, .2), 0em 0em 0em .4em rgba(0, 182, 74, 0);
    }
}


/*************************/
/*    ReadyAddisonButton   */
/*************************/

#readyaddison {
    top: 16vw;
    background: #00B64A;
}

#readyaddison:focus {
    animation: borderPulseYes 1000ms infinite ease-out;
    animation: borderPulseYes 1s;
    background: rgba(0, 182, 74, 1);
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#readyaddison:focus .checkMark {
    fill: #fff;
}

@keyframes borderPulseYes {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 182, 74, .4), 0em 0em 0em 0em rgba(0, 182, 74, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 182, 74, .2), 0em 0em 0em .4em rgba(0, 182, 74, 0);
    }
}


/*************************/
/*       AlertTab      */
/*************************/

.alertBackground {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 60vh;
    border-radius: .75vw;
    background-color: rgba(58, 68, 86, 1);
}

.alertTitle {
    padding: 2vw 0vw 0vw 0vw;
    background-color: #ffbb33;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 13vh;
    font-family: "Roboto Condensed";
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
    color: #3A4456;
}

.alertText {
    padding: 1vw;
    font-family: "Roboto Condensed";
    font-size: 2.5vw;
    text-align: center;
    color: #DAE3EB;
}

#proHealthSVG {
    position: flex;
    margin-top: 0.5vw;
}


/*************************/
/*        Captions       */
/*************************/

#captionGradient {
    position: absolute;
    width: 90vw;
    height: 10vh;
    top: 90vh;
    background: -webkit-radial-gradient(bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    z-index: 1000; /*jkeys addition 1/1/19*/
}

#captions {
    width: 43vw;
    height: 10vh;
    margin-left: 2vw;
    line-height: 3.5vh;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 2.9vh;
    font-weight: 300;
    text-align: left;
    position: fixed;
	top: 90vh;
}

.captions {
    width: 43vw;
    height: 10vh;
    margin-left: 2vw;
    line-height: 3.5vh;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 2.9vh;
    font-weight: 300;
    text-align: left;
    position: fixed;
    top: 90vh;
}


/*************************/
/*     GreatJobTab     */
/*************************/

.TakenScreen {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 60vh;
    border-radius: .75vw;
    background-color: #3A4456;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    color: #DAE3EB;
}

.GreatJob {
    background-color: #009F93;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 14vh;
    width: 35vw;
    font-family: "Roboto Condensed";
    font-size: 4vw;
    text-align: center;
    display: inline-block;
    padding-top: 0.6vw;
}

.Streak {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 8vw;
    color: #DAE3EB;
    padding: 0vw;
}


.progress-segment {
    display: flex;
    margin: 2vw 1vw 2vw 1vw;
}

.item {
    width: 100%;
    background-color: #D2D6E8;
    margin-right: 0.3vw;
    height: 3vw;
    border-radius: 0.3vw;
}

.item:first-child {
    border-top-left-radius: 0.75vw;
    border-bottom-left-radius: 0.75vw;
}

.item:last-child {
    border-top-right-radius: 0.75vw;
    border-bottom-right-radius: 0.75vw;
}

.item.segOne {
    background: #4BCBF3 !important;
}

.item.segTwo {
    background: #53cdf4 !important;
}

.item.segThree {
    background: #5fd1f4 !important;
}

.item.segFour {
    background: #6cd4f5 !important;
}

.item.segFive {
    background: #79d7f6 !important;
}

.item.segSix {
    background: #85daf6 !important;
}

.item.segSeven {
    background: #8CDDF7 !important;
}


/*************************/
/*        Help_Tab       */
/*************************/

.helpTab {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 45vh;
    border-radius: .75vw;
    margin-left: 45vw;
    background-color: rgba(58, 68, 86, 1);
}

.btnrefreshpage {
    float: left;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #B4443E;
    cursor: pointer;
    border-radius: .5vw 0vw .5vw 0vw;
    padding-bottom: .5vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 12vw;
}

.btnclosehelp {
    float: right;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #B4443E;
    cursor: pointer;
    border-radius: 0vw .75vw 0vw .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 10.5vw;
}

#btnRR:focus,
#btnCS:focus,
#btnPMD:focus {
    animation: borderPulseHelp 1000ms infinite ease-out;
    animation: borderPulseHelp 1s;
    background-color: #49C0AD;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@keyframes borderPulseHelp {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}

@keyframes borderPulseHelp {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}


/*************************/
/* MedicationChartTab */
/*************************/

.med-table {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    border-radius: 1vw;
    overflow: hidden;
    font-family: "Open Sans", sans-serif;
    text-align: center;
}

.med-table,
th {
    border: 0px;
    color: #EEF0F6;
    background: #045C84;
    font-size: 1.7vw;
    padding: 2vw 4vw 2vw 4vw;
    table-border: 0;
    border-collapse: collapse;
    cellspacing: 0;
}

.med-table,
tr,
td {
    background: #DAE3EB;
    color: #000;
    font-size: 1.2vw;
    padding: 2vw;
}

.med-table,
tr:nth-child(odd) td {
    background: #D2D6E8;
}


/*************************/
/*      NextMedTab     */
/*************************/

.NextBackground {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 60vh;
    border-radius: .75vw;
    background-color: #3A4456;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 3vw;
    color: #DAE3EB;
}

.NextTitle {
    padding: 0.6vw 0vw 0.6vw 0vw;
    background-color: #8DA9C4;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 13vh;
    font-family: "Roboto Condensed";
    font-size: 3vw;
    text-align: center;
}

.YourNextMed {
    padding: 2vw;
    font-size: 2.2vw;
}

.NextMedDate,
.NextMedTime {
    padding: 2vw;
    font-size: 3vw;
}


/*************************/
/*   SnoozeTimer   */
/*************************/

.timerBox {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    display: flex;
    width: 30vw;
    height: 30vw;
    background: #3A4456;
    border-radius: .7vw;
}

.c-container {
    position: relative;
    width: 100%;
    padding: 5vw;
    margin: auto;
}

.c-container .c-text {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #DAE3EB;
    font-size: 2vw;
    font-family: "Open Sans", sans-serif;
    line-height: 2vw;
    top: 50%;
    margin-left: -5vw;
    margin-top: 3vw;
}

.c-container svg {
    width: 100%;
}


/*************************/
/*   BPDiagram_Summary   */
/*************************/

#bp-diagram-container {
    height: 45vh;
}


/*************************/
/*   HRDiagram_Summary   */
/*************************/

#heart-rate-summary {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    height: 8vh;
    background-color: #00B54A;
    color: #EEF0F6;
    /*   background-color: #00b64a; */

}

#hr-diagram-container {
    height: 45vh;
}


/*************************/
/* OxygenDiagram_Summary */
/*************************/

#oxygen-diagram-container {
    display: block;
    height: 45vh;
}


/*************************/
/*  ScaleDiagram_Summary */
/*************************/

#scale-diagram-container {
    height: 45vh;
}


/*************************/
/*    Summary_Diagram    */
/*************************/

html {
    display: block;
}

div {
    display: block;
}

.addison-captions {
    display: block;
    text-align: center;
}

.diagram {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #D2D6E8;
    background: #3A4456;
    cursor: pointer;
    border-radius: .75vw;
    border: 0vw;
    text-align: center;
    width: 35vw;
    height: 80vh;
}

div.canvas {
    display: block;
    padding: 0;
    margin: 0;
    flex: 1;
    height: 60vh;
}

.summary-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -1.4vw;
    padding-top: 1vw;
    background: #758299;
    border-radius: 0.75vw 0.75vw 0 0;
    height: 7vw;
}

#summary-header-text {
    padding-right: 3.5vw;
    padding-top: 1.5vw;
}

#streakContainer {
    height: 0vw;
    font-size: 2vh;
}

#streakTitle {
    margin: -1.45vw 0vw 0vw 1.5vw;
}

#achievement-score {
    margin: auto;
    padding-top: 2.6vh;
    margin-right: 0vw;
}


#achievement-container {
    display: flex;
    width: 50%;
}


#summary-header-achievement {
    padding-right: 2.5vw;
    height: 100%;
}


#youCanSay {
    margin: 1vw;
    margin-left: 1.5vw;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
    float: left;
    font-size: 1.25vw;
    font-weight: normal;
}

.utterances {
    display: none;
    font-size: 1.25vw;
    font-weight: normal;
    float: right;
}

#bar0 {
    background: #35aecd !important; 
}

#bar1 {
       background-color: #35aecd !important;  
}

#bar2 {
       background-color: #35aecd !important;  
}

#bar3 {
       background-color: #35aecd !important;   
}

#bar4 {
       background-color: #35aecd !important;  
}

#bar5 {
       background-color: #35aecd  !important; 
}

#bar6 {
    /*   background-color: #3FC0DD  !important; */
}

.achievement-button {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.25vw;
    margin-left: 0.25vw;
    justify-content: flex-end;
    cursor: pointer;
    border-radius: 0.75vw;
    width: 3.25vw;
    height: 3vh;
    background-color: #D2D6E8;
}

.left-achievement {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.right-achievement {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.middle-achievement {
    border-radius: 0;
}

.summary-button {
    display: flex;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5vw;
    cursor: pointer;
    color: #EEF0F6;
    background-color: #0098C6;
    width: 32vw;
    height: 8vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    border: 0vw;
    border-radius: 0.75vw;
}

.summary-button:active {
    background-color: #758299;
    transform: translateY(2px);
}

#heart-summary {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
}

.result {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 2vw;
}

#heart-rate-summary {
    margin-top: 2vw;
    background-color: #00B54A;
    color: #EEF0F6;
    /*   background-color: #00b64a; */

}

#oxygen-levels-summary {
    background-color: #00B54A;
    color: #EEF0F6;

}

#hr-chart canvas {
    display: "block"
}

#oxygen-value {}

#blood-pressure-summary {
    color: #EEF0F6;
    background-color: #B51E2D;

}

#bp-value {}

#weight-summary {
    color: #EEF0F6;
    background-color: #00B54A;
    /*  background-color: rgba(238, 27, 35, .75); */

}

#temperature-summary {
    color: #EEF0F6;
    background-color: #B51E2D;

}

.icon-svg {
    float: left;
    padding: 0vw 2vw 0vw 2vw;
    margin-top: auto;
    margin-bottom: auto;
}

.vital-name {
    font-size: 1.5vw;
    margin-top: auto;
    margin-bottom: 0.5vh;
    margin-left: 1vw;
}

.vitals-text {
    padding-right: 2vw;
}

.summary-back-button {
    font-family: "Open Sans", sans-serif;
    font-size: 1vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #0098C6;
    cursor: pointer;
    border-radius: 0vw 0.5vw 0vw 0.5vw;
    border: 0vw;
    width: 12vw;
    height: 3vw;
    margin-top: -1vw;
}

.summary-vitals-text {
    width: 17vw;
    padding-right: 9vw;
    padding-top: 1.5vw;
}


#hr-levels-summary {

    background-color: #B51E2D;
    color: #EEF0F6;
}

.chart-legend {
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: -4.9vw;

}

.green-label {
    background-color: #00b64a;
    height: 2vw;
    width: 4vw;
    border-radius: 0.35vw;

}

.yellow-label {
    background-color: #ffbb33;
    height: 2vw;
    width: 4vw;
    border-radius: 0.35vw;

}

.red-label {
    background-color: #b4443e;
    height: 2vw;
    width: 4vw;
    border-radius: 0.35vw;
    margin-left: 2.5vw;

}

.label-text {
    width: 1vw;
    margin-left: 4.5vw;
    margin-top: 0.25vw;
    font-size: 1vw;
    color: #EEF0F6;
    text-align: left;
}

.two-item-row {
    width: 10vw;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


/*************************/
/*  TempDiagram_Summary  */
/*************************/

#temp-diagram-container {
    height: 45vh;
}


/*************************/
/*   BGDiagram_Summary   */
/*************************/

#bg-diagram-container {
    height: 45vh;
}


/*************************/
/*      VideoEntity      */
/*************************/

video {
    width: 100vw;
    height: auto;
    display: block;
    /*object-fit: fill;*/
}

.video-container {
    top: 25vh;
    /* shift the container down */
    bottom: 25vh;
    /* shift the container up */
    left: 50vw;
    /* shift the container to the right of the screen */
    right: 10vw;
    /* give the container a right-margin of 10% viewport width */
    width: auto;
    height: 50vh;
    /* auto height so the video is sized correctly */
    position: fixed;
    display: block;
}

/*************************/
/*  Html_Pendant_Pressed */
/*************************/

.pendantTab {
    position: fixed;
    display: flex;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 60vh;
    border-radius: .75vw;
    background-color: #3A4456;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    color: #3A4456;
}

.pendantBanner {
    display: float;
    background-color: #ffbb33;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 14vh;
    width: 120%;
    font-family: "Roboto Condensed";
    font-size: 3vw;
    text-align: center;
    padding-top: 0.6vw;
}

.pendantText {
    padding: 1vw;
    font-family: "Roboto Condensed";
    font-size: 3vw;
    text-align: center;
    color: #DAE3EB;
}

.containerA {
    position: fixed;
    top: 14vw;
    bottom: 5vw;
    right: 10vw;
    font-family: "Open Sans", sans-serif;
    font-size: 1.75vw;
    color: #D2D6E8;
    background: #3A4456;
    cursor: pointer;
    border-radius: .75vw;
    border: 0vw;
    text-align: center;
    width: 35vw;
    height: 46.5vh;
}

.btnWide {
    font-family: "Open Sans", sans-serif;
    font-size: 3vw;
    color: #d2d6e8;
    font-weight: bold;
    cursor: pointer;
    border-radius: 1vw;
    padding-bottom: 1vw;
    border: none;
    align-items: center;
    text-align: center;
    width: 35vw;
    background: b00b00;

    position: fixed;
    right: 12.5vw;
    z-index: 5;

    background-position: center;
    transition: background .8s;
}


/*************************/
/*       Html_Yoga       */
/*************************/

#yogaVideo {
    position: fixed;
    height: 100vh;
    right: 0vw;

}


/*************************/
/*     Html_HulaHoop     */
/*************************/

#hulaHoopVideo {
    position: fixed;
    width: 100vw;
    height: 100vh;
}


/**************************/
/* Html_BreathingExercise */
/**************************/

#breathingExerciseVideo {
    position: fixed;
    width: 100vw;
    height: 100vh;
}


/*************************/
/* Html_diabetesSmoking  */
/*************************/

#diabetesSmokingTab {
    position: fixed;
    display: flex;
    top: 3.5vw;
    bottom: 5vw;
    right: 10vw;
    width: 40vw;
    height: 85vh;
    border-radius: .75vw;
    background-color: #3A4456;
}

#diabetesSmokingBanner {
    position: fixed;
    width: 40vw;
    height: 7vw;
    border-radius: 0.75vw 0.75vw 0 0;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 3vw;
    color: #3A4456;
    padding-top: 1vw;
    background: #ffbb33;
}

#diabetesSmokingText {
    color: #DAE3EB;
    position: fixed;
    width: 35vw;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5vw;
    color: #DAE3EB;
    padding: 2vw;
    padding-top: 9vw;
}

#smokingNumber {
    position: fixed;
    top: 43.5vw;
    left: 65.6vw;
    font-size: 1vw;
    font-family: OpenSans, Open Sans;
}


/****************************/
/* Html_heartDiseaseSmoking */
/****************************/

#heartDiseaseSmokingTab {
    position: fixed;
    display: flex;
    top: 3.5vw;
    bottom: 5vw;
    right: 10vw;
    width: 40vw;
    height: 85vh;
    border-radius: .75vw;
    background-color: #3A4456;
}

#heartDiseaseSmokingBanner {
    position: fixed;
    width: 40vw;
    height: 7vw;
    border-radius: 0.75vw 0.75vw 0 0;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 3vw;
    color: #3A4456;
    padding-top: 1vw;
    background: #ffbb33;
}

#heartDiseaseSmokingText {
    color: #DAE3EB;
    position: fixed;
    width: 35vw;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5vw;
    color: #DAE3EB;
    padding: 2vw;
    padding-top: 9vw;
}


/*************************/
/*    Html_PollenCount   */
/*************************/

#pollenTab {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 60vh;
    border-radius: .75vw;
    background-color: #3A4456;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    color: #DAE3EB;
}

#pollenBanner {
    background-color: #009F93;
    padding: 2vw 0vw 0vw 0vw;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 14vh;
    font-family: "Roboto Condensed";
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
}

#pollenText {
    color: #DAE3EB;
    margin-left: auto;
    margin-right: auto;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    color: #DAE3EB;
    padding: 0.5vw;
    text-align: center;
}

#pollen_city {
    font-size: 3vw;
    margin: 1vw;
}

#pollen_name {
    font-size: 2.5vw;
    margin-top: 5vw;
}

.pollen-slider {
    margin: 2vw 0 0 0;
    width: 100%;
}

.pollen-slider__range {
    -webkit-appearance: none;
    width: 25vw;
    height: 6vw;
    border-radius: 0.75vw;
    background: -webkit-gradient(linear, left top, right top, from(#00B64A), color-stop(50%, #ffbb33), to(#B4443E));
    background: -webkit-linear-gradient(left, #00B64A 0%, #ffbb33 50%, #B4443E 100%);
    background: -o-linear-gradient(left, #00B64A 0%, #ffbb33 50%, #B4443E 100%);
    background: linear-gradient(to right, #00B64A 0%, #ffbb33 50%, #B4443E 100%);
    outline: none;
    padding: 0;
    margin: 0;
    margin-top: -0.5vw;
    margin-bottom: 1.5vw;
}

.pollen-slider__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5vw;
    height: 7.5vw;
    border-radius: 0.5vw;
    background: #EEF0F6;
    cursor: arrow;
}

.pollen-slider__value {
    display: block;
    position: fixed;
    width: 5vw;
    color: #3A4456;
    line-height: 3vw;
    text-align: center;
    border-radius: 0.75vw;
    background: #D2D6E8;
    margin-left: 15%;
    margin-top: 1vw;
    font-family: "Roboto Condensed";
    font-size: 2.5vw;
    text-align: center;
    color: #111;
}

#pollenLowHigh {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: center;
    margin: -5.25vw 1vw 9vw 1vw;
    font-size: 1vw;
}


/*************************/
/*    Html_AddisonTab    */
/*************************/

.addisonTab {
    position: fixed;
    top: 7vw;
    right: 10vw;
    width: 80vw;
    height: 70vh;
    border-radius: .75vw;
    margin-left: 45vw;
    background-color: rgba(58, 68, 86, 1);
}

#addisonBanner {
    padding: 2vw 0vw 0vw 10vw;
    background-color: #0098C6;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 8vw;
    font-family: "Roboto Condensed";
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
    color: #d2d6e8;
}

.btncloseaddison {
    float: right;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #B4443E;
    cursor: pointer;
    border-radius: 0vw .75vw 0vw .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 10.5vw;
    margin-top: -2vw;
}

#addisonBtnContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;
    align-items: center;
    margin: 0 1vw 0 1vw;
}

#btnTakeMyMeds,
#btnNextMed {
    margin: 0vw;
    margin-top: 5vw;
}

#linebreak {
    width: 100%;
}

.icon, .text {
  display: flex;
  flex-wrap: wrap;
  align-items: center
  }

.begin-btn {
 font-family: "Open Sans", sans-serif;
 font-size: 4vw;
 color: #d2d6e8;
 font-weight:bold;
 background: #016E75;
 cursor: pointer;
 border-radius: 1vw;
 padding-bottom: 1vw;
 border: 0vw;
    align-items: center;
 text-align: center;
 width: 20vw; /*golden ratio lel*/
 height: 14vh;
 top: 40vh;
 left: 40vw;
 position: absolute;
 }

.begin-btn:active,

.begin-btn:focus {
 animation: borderPulseDone 1000ms infinite ease-out;
 animation: borderPulseDone 1s;
 background-color: #49C0AD;
 transition: background 1s;
 outline: 0em;
   color: #ffffff;
}

/* Declare border pulse animation */
@keyframes borderPulseDone {
/* inside pulse */
  0% {
    box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
  }
/* outside pulse */
  100% {
    box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
  }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
 animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
  background-position: center;
  transition: background .8s;
}

/* Gradient properties */
button.btndone {
  background-position: center;
  transition: background .8s;
}


/*************************/
/*  Html_vitalsTutorials */
/*************************/

#vitalsTutorialImages {
    position: fixed;
    top: 0vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;

}


/****************************/
/* HTML_CalibrationInstruct */
/****************************/

.calibrationBackground {
    height: 1100px;
    width: 2200px;
    background-color: #3A4456;
}

.calibrationInstructions {
    padding-top: 20vh;
    font-family: "Open Sans", sans-serif;
    font-size: 50px;
    color: #EEF0F6;
    cursor: pointer;
    text-align: center;
}

.calibrationSteps {
    font-family: "Open Sans", sans-serif;
    font-size: 30;
    color: #EEF0F6;
    text-align: center;
}

.calibrationCam {
    font-family: "Open Sans", sans-serif;
    font-size: 30;
    color: #8CDDF7;
    text-align: center;
}

.calibrationMic {
    font-family: "Open Sans", sans-serif;
    font-size: 30;
    color: #D2B2DD;
    text-align: center;
}

.calibrationSpeak {
    font-family: "Open Sans", sans-serif;
    font-size: 30;
    color: #49C0AD;
    text-align: center;
}

.calibrationSpeedTest {
    font-family: "Open Sans", sans-serif;
    font-size: 30;
    color: #fff;
    text-align: center;
}

.calibrationClose {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    color: #EEF0F6;
    text-align: center;

    margin-left: 1000px;
    background: #B4443E;
    border-radius: 10px;
    border: none;
    width: 200px;
    height: 75px;
    overflow: hidden;
}

.instructionsBtn {
    position: fixed;
    top: 5vw;
    left: 5vw;
    font-family: "Open Sans", sans-serif;
    font-size: 3em;
    color: #ffffff;
    background: #00C851;
    cursor: pointer;
    border-radius: .4em;
    padding-bottom: 0.2em;
    border: 0em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


/*************************/
/*    Calibration_Cam    */
/*************************/

.camera {
    position: fixed;
    top: 10vw;
    left: 57vw;
    width: 30vw;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 5em;
    color: #d2d6e8;
    background: #0b2545;
    cursor: pointer;
    border-radius: 0.75vw;
    padding-bottom: 0.2em;
    border: 0em;
}

/* What happens on mouse-down */
.camera:active,

/* What happens once selected */
.camera:focus {
    animation: borderPulseCamera 1000ms infinite ease-out;
    animation: borderPulseCamera 1s;
    background-color: #045c84;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

/* Declare border pulse animation */
@keyframes borderPulseCamera {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(4, 92, 132, .4), 0em 0em 0em 0em rgba(4, 92, 132, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(4, 92, 132, .2), 0em 0em 0em .4em rgba(4, 92, 132, 0);
    }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
    animation: borderPulse1 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    background-position: center;
    transition: background .8s;
}

/* Gradient properties */
button.speakers {
    background-position: center;
    transition: background .8s;
}


/*************************/
/*    Calibration_Mic    */
/*************************/

.microphone {
    position: fixed;
    top: 20vw;
    left: 57vw;
    width: 30vw;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 5em;
    color: #d2d6e8;
    background: #441155;
    cursor: pointer;
    border-radius: 0.75vw;
    padding-bottom: 0.2em;
    border: 0em;
}

/* What happens on mouse-down */
.microphone:active,

/* What happens once selected */
.microphone:focus {
    animation: borderPulseMic 1000ms infinite ease-out;
    animation: borderPulseMic 1s;
    background-color: #bb84d0;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}


/* --------------- Animation --------------- */

/* Declare border pulse animation */
@keyframes borderPulseMic {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(187, 132, 208, .4), 0em 0em 0em 0em rgba(187, 132, 208, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(187, 132, 208, .2), 0em 0em 0em .4em rgba(187, 132, 208, 0);
    }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
    animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    background-position: center;
    transition: background .8s;
}

/* Gradient properties */
button.microphone {
    background-position: center;
    transition: background .8s;
}


/*************************/
/*  Calibration_Speakers */
/*************************/

.speakers {
    position: fixed;
    top: 30vw;
    left: 57vw;
    width: 30vw;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 5em;
    color: #d2d6e8;
    background: #03312e;
    cursor: pointer;
    border-radius: 0.75vw;
    padding-bottom: 0.2em;
    border: 0em;
}

/* What happens on mouse-down */
.speakers:active,

/* What happens once selected */
.speakers:focus {
    animation: borderPulseSpeakers 1000ms infinite ease-out;
    animation: borderPulseSpeakers 1s;
    background-color: #49c0ad;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

/* Declare border pulse animation */
@keyframes borderPulseSpeakers {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(73, 192, 173, .4), 0em 0em 0em 0em rgba(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(73, 192, 173, .2), 0em 0em 0em .4em rgba(73, 192, 173, 0);
    }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
    animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    background-position: center;
    transition: background .8s;
}

/* Gradient properties */
button.speakers {
    background-position: center;
    transition: background .8s;
}


/*************************/
/*   Calibration_Speed   */
/*************************/

.speed {
    position: fixed;
    top: 40vw;
    left: 57vw;
    width: 30vw;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 5em;
    color: #d2d6e8;
    background: #3a4456;
    cursor: pointer;
    border-radius: 0.75vw;
    padding-bottom: 0.2em;
    border: 0em;
}


/* What happens on mouse-down */
.speed:active,

/* What happens once selected */
.speed:focus {
    animation: borderPulseSpeed 1000ms infinite ease-out;
    animation: borderPulseSpeed 1s;
    background-color: #758299;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

/* Declare border pulse animation */
@keyframes borderPulseSpeed {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(117, 130, 153, .4), 0em 0em 0em 0em rgba(117, 130, 153, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(117, 130, 153, .2), 0em 0em 0em .4em rgba(117, 130, 153, 0);
    }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
    animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    background-position: center;
    transition: background .8s;
}

/* Gradient properties */
button.speed {
    background-position: center;
    transition: background .8s;
}


/*************************/
/* Calibration_SpeedTest */
/*************************/

.corner-wrapper {
    position: fixed;
    top: 40vw;
    left: 57vw;
    width: 28.4vw;
    display: block;
    overflow: hidden;
    height: 180px;
    border-radius: 0.75vw;
    transform: translateZ(10px);
    border: 15px solid #fff;
}

#speedTestIframe {
    width: 30vw;
    height: 15vw;
    border: none;
    frameborder: none;
    position: relative;
    left: -35%;
    top: -45%;
}

/*****************************/
/* Calibration_HideSpeedTest */
/*****************************/

.HideSpeedTest {
    position: fixed;
    top: 40vw;
    left: 88vw;
    font-family: "Open Sans", sans-serif;
    font-size: 3em;
    color: #ffffff;
    background: #cc0303;
    cursor: pointer;
    border-radius: .4em;
    padding-bottom: 0.2em;
    border: 0em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


/*************************/
/*   IFRAME_SpeedTest    */
/*************************/


.corner-wrapper {
    display: block;
    overflow: hidden;
    width: 475px;
    height: 180px;
    border-radius: 10px;
    transform: translateZ(10px);
    border: 15px solid #fff;
    z-index: 25;



}

#ifrSpeedTest {
    width: 787px;
    height: 400px;
    border: none;
    frameborder: none;


    position: relative;
    left: -35%;
    top: -45%;
}

/*************************/
/*    Html_HelpScreen    */
/*************************/

.helpScreenBackground {
    position: fixed;
    width: 100vw;
    height: 100vh;

    background-image: url("https://s3.amazonaws.com/addison-project-anim-files/UI+Images/Vitals+Tutorial+Images/TabletUI_BLANK_13-5x9AR_v01_01.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
}

.helpScreenTitle {
    padding: 2vw 0vw 2vw 0vw;
    background-color: #009F93;
    height: 19vh;
    font-family: "Roboto Condensed";
    font-size: 8vw;
    font-weight: bold;
    text-align: center;
    color: #EEF0F6;
    box-shadow: 0vw 0vw 3vw #009F93;
}

.helpScreenText {
    padding: 2vw;
    font-family: "Roboto Condensed";
    font-size: 5vw;
    text-align: center;
    color: #DAE3EB;
}

/*************************/
/*    Html_CSAudioVis    */
/*************************/

.CSAudVis {
    font-family: sans-serif;
    text-align: center;
    color: #009F93;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CSAudVis>div {
    display: inline-block;
    width: 0.75vw;
    height: 15vw;
    margin: 2vw 2vw 2vw;
    border-radius: 0.75vw;
    background: currentColor;
    transform: scaleY(0.5);
    opacity: .25;
    box-shadow: 0vw 0vw 4vw #009F93;
}

.CSAudVis.error {
    color: #f7451d;
    min-width: 20em;
    max-width: 30em;
    margin: 0 auto;
    white-space: pre-line;
}

/*************************/
/*   Html_HelpAudioVis   */
/*************************/

.HelpAudVis {
    font-family: sans-serif;
    text-align: center;
    color: #009F93;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HelpAudVis>div {
    display: inline-block;
    width: 0.75vw;
    height: 15vw;
    margin: 2vw 2vw 2vw;
    border-radius: 0.75vw;
    background: currentColor;
    transform: scaleY(0.5);
    opacity: .25;
    box-shadow: 0vw 0vw 4vw #009F93;
}

.HelpAudVis.error {
    color: #f7451d;
    min-width: 20em;
    max-width: 30em;
    margin: 0 auto;
    white-space: pre-line;
}

/*************************/
/* Html_PendantAudioVis  */
/*************************/

.PendantAudVis {
    font-family: sans-serif;
    text-align: center;
    color: #009F93;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PendantAudVis>div {
    display: inline-block;
    width: 0.75vw;
    height: 15vw;
    margin: 2vw 2vw 2vw;
    border-radius: 0.75vw;
    background: currentColor;
    transform: scaleY(0.5);
    opacity: .25;
    box-shadow: 0vw 0vw 4vw #009F93;
}

.PendantAudVis.error {
    color: #f7451d;
    min-width: 20em;
    max-width: 30em;
    margin: 0 auto;
    white-space: pre-line;
}

#nutritionPlan-header-text {
 font-size: 6vh;
 padding-top: .5vw;
}

.long-header-text {
 font-size: 4.5vh;
 padding-top: 1vw;
}


/*************************/
/*      Html_MicDot      */
/*************************/

#stuff:focus {outline:0;}
.micDotON {
  position: absolute;
  top: 1vw;
  left: 95vw;
  background-color: #00B64A;
  border: none;
  width: 4vw;
  height: 4vw;
  border-radius: 50vw;
}
.micDotOff {
  top: 1vw;
  left: 95vw;
  position: absolute;
  background-color: #B4443E;
  border: none;
  width: 3vw;
  height: 3vw;
  border-radius: 50vw;
}







/*************************/
/*      CFRSI Gait       */
/*************************/




/* ############# Pagination Style  ############ */
YN
.highlight-question {
    background-color: white;
}


.container{
    display: block;
    position: absolute;
    width: 900px; 
    height: 800px;
    top: 30px;
    bottom: 25px;
    left: 57em;
    border: 6px solid;
    border-color: #30476C;
    border-radius: 20px;
    background-color: rgba(179,197,228,0.6);
    /*padding: 2.5em 2em;*/
    margin: 0;
}


label {
    width:78%;
    height:40%;
    display: inline-block;
    font-size:28px;
}


.YN {
    float: right;
    margin-left:10px;
    margin-top: 60px;
    height: 100px;
    width: 130px;
    border-radius:23px;
    border-style:solid;
    border-color:#070d18;
    margin-bottom:0;
    font-size:28px;
    color:white;
    background-color:#30476C;
    text-align: center;
}

.UD {
    float: right;
    margin-left:10px;
    height: 75px;
    width: 130px;
    border-radius:23px;
    border-style:solid;
    border-color:#070d18;
    margin-bottom:0;
    font-size:26px;
    color:white;
    background-color:#30476C;
    text-align: center;
}


.userInput {
    float:right;
    margin-left:25px;
    height: 85px;
    width: 270px;
    border-radius:23px;
    border-style:solid;
    border-color:#070d18;
    margin: 10px 0px;
    font-size:28px;
    color:white;
    background-color:#30476C;
    text-indent: 15px;
}


.pagination-items{
    width: 91%;
    height: 97%;
    margin:auto;
    padding: auto;
    padding-top: 5vh;
}


.pagination {
    position: static;
    width: 50%;
    float: right;
    margin-top: 70px;
    /*margin-right: auto;
    margin-bottom: 10em;
    margin-left: 20em;*/
    text-align: center;
}


.pagination:after {
    position:static;
    /*content: ''; */
    clear: both;
}


.pagination-button {
    visibility: hidden;
    position:static;
    font-size:56px;
    display: inline-block;
    padding: 0px 40px;
    background-color: white;
    border: 2px solid #30476C;
    border-radius: 43px;
    color: #30476C;
    cursor: pointer;
    transition: background 0.1s, color 0.1s;
}

.pagination-button.page-num {
    visibility: hidden;
    position:static;
    font-size: 6px;
    display: inline-block;
    padding: 2px 0px;
    background-color: white;
    border: 2px solid #30476C;
    border-radius: 43px;
    color: #30476C;
    cursor: pointer;
    transition: background 0.1s, color 0.1s;
}



.pagination-button:hover {
    position:static;
    background-color: #ddd;
    color: #36c;
}


.pagination-button.active {
    position:static;
    background-color: #bbb;
    border-color: #bbb;
    color: #36c;
}


.pagination-button:first-of-type {
    position:static;
    visibility: visible;
}


.pagination-button:last-of-type {
    position:static;
    visibility: visible;
}


/* arbitrary styles */
.heading {
    text-align: center;
    max-width: 500px;
    //margin: 20px auto;
}


.article-loop {
    border-radius: 20px;
    display: inline-block;
    width: 95%;
    padding: 14px 9px;
    margin: 10px 5px;
    font-size: 40px;
    border: 6px solid #30476C;
    background-color: #B3C5E4;
}

.article-loop h1{
    float: left;
    width: 60%;
    font-weight: bold;
    font-size: 30px;
}

.article-loop label{
    float: left;
    width: 75%;
    padding: 10px;
    font-weight: bold;
    font-size: 20px;
}

.pages {
    visibility: hidden;
    width:9px;
    padding:0px;
}


.invisible-article {
    width: 0px;
    height: 0px;
    /*visibility: hidden;*/
    display: none;
}

/* ######### AddisonResponse ######## */


.addisonText h1{
    width: 60em;
    height: 4.5em;
    margin: auto;

    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
}

.addisonText p {
    width: 60em;
    margin-top: 33em;
    margin-left: 3em;
    padding-top: 0.5em;
    line-height: 1.3em;

    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    font-weight: 400;
    text-align: center;
}

/* ########## UserResponse ######### */


#addisonResponse textarea {
    align: center;
    margin: 5px;
    position: center;
    text-align: left;
    border-radius: 1em;
}


#userText {
    width:220px;
    height: 75px;
    border-radius: 10px;
    border: 3px solid;
    border-color:#30476C;
    font-size: 28px;
}


.submit-button {
    width: 30%;
    border: 2px solid;
    height: 30%;
    background: white;
    border-radius: 38px;
    border-color: #30476C;
    font-weight: bold;
    font-size: 28px;
    color: #30476C;
    padding: 15px 0px 15px 0px;
    font-family: Roboto;
}

.submit-button:hover {
    color:white;
    background: var(--button-color);
}


/* ########## Agreement ########## */

* {
    font-family: 'Roboto', sans-serif;
    --button-color: #30476C;
    --circle-color: #bbbbbb;
}


.agreementDiv{
    position: absolute;
    width: 800px;
    height: 780px;
    overflow: auto;
    border-radius: 23px;
    border-style: solid;
    border-color: #070d18;
    background-color: #B3C5E4;
    padding: 1% 2%;
    font-family: 'Roboto';
    font-size: 26px;
    left: 40em;
    top: 1em;
}

.termsbtn {
    float: right;
    margin-left:10px;
    height: 75px;
    width: 130px;
    border-radius:32px;
    border-style:solid;
    border-color:#070d18;
    margin-bottom:0;
    font-size:26px;
    color:white;
    background-color:#30476C;
    text-align: center;
}

.termsbtn:active {
    color: var(--button-color);
    background-color:#fff;
    letter-spacing: 5px;
}

::-webkit-scrollbar {
    width: 20px !important;
}

::-webkit-scrollbar:vertical {
    width: 20px !important;
}

::-webkit-scrollbar:horizontal {
    width: 20px !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background: #0B2545 !important;
}

::-webkit-scrollbar-track {
    border-radius: 10px !important;
    background: #DAE3EB !important;
}


/* ##########  YesNoButtons ######### */

* {
    font-family: 'Roboto', sans-serif;
    --button-color: #30476C;
    --circle-color: #bbbbbb;
}


.is-paused {
    animation-play-state: paused;
}


.spin-button {
    position: static;
    width: 200px;
    height:70px;
    margin: 0px 0px 0px 20px;
    outline: none;
    text-align: center;
    border-radius: 100px;
    background: #fff;
    border: .25vw solid;
    color: var(--button-color);
    letter-spacing: 1px;
    text-shadow:5;
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;
    transition: all 0.15s ease;
    font-family: 'Roboto';
}


.spin-button:after {
    /*content:"";  */
}


.spin-button:hover{
    color:white;
    background: var(--button-color);
}


.spin-button:active{
    letter-spacing: 5px ;
}


.onclic {
    position: static;
    /*margin: auto;*/
    width: 70px;
    height: 70px;
    border-color: var(--circle-color);
    border-width:5px;
    font-size:0;
    border-left-color:var(--button-color);
    animation: rotating 1.0s .25s linear infinite;
}


.onclic:after {
    content:"";
}


.onclic:hover {
    color: var(--button-color);
    background: white;
}


.validate {
    font-size:28px;
    color: white;
    background: var(--button-color);

}


.validate:after {
    font-family:'FontAwesome';
    /* content:"\f00c"; */
}


@keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

/*
#yes-button{
    visibility:visible;
}


#no-button{
    visibility:visible;
}
*/


.YN-buttons {
    display: flex;
    position: absolute;
    top: 8vh;
} 


/* ##########  Begin Button ######### */




.moveBegin {
    left: 33em;
    top: 21em;

} 

 
.begin {
    top: 36em;
    left: 26em;
    width: 1000px;
    height: 590px;


}

.select-button {
line-height: 20px;
    font-weight: bold;
    font-size: 26px;
    color: #30476C;
    border: .25vw solid;
    color: #30476C;
    background-color: white;
    border-color: #30476C;
    margin-left: 26px;
    margin-top: 26px;
    margin-bottom: 15px;
    border-radius: 40px;
    width: 140px;
    height: 75px;
}


#beginButton{
    border: .25vw solid;
    font-size:70px;

}

/* ##########  Help Button ######### */



.helpDiv{
    margin-top:100px;
    margin-bottom:100px;
    margin-left:50px;
    width:100px;
    height:100px;
    border-radius:50px;
  outline-style: none;
}

.selectors{
    margin-top:600px;
    margin-bottom:100px;
    margin-left:1200px;
    width:100px;
    height:100px;
    border-radius:50px;
  outline-style: none;
}

.question{
  
    padding-left: 0px;
    padding-right: 0px;
    padding-top:2px;
    color: white;
    background-color: #30476C;
    border-radius: 65px;
    border-color: #30476C;
    position: relative;
    height: 75px;
    width: 75px;
    margin: 25px;
    border: none;
   
    }

 .question:active{

  color: #30476C;
  background-color: white;
  border-color:white;
   
}

  
.question:after {
  outline-style: none;
    font-family:'FontAwesome';
    /* content:"\f059"; */
    font-size: 72px;
}


.restartButton{
    line-height: 20px;
    font-weight: bold;
    font-size: 26px;
    color: #30476C;
    border: .25vw solid;
    color: #30476C;
    background-color: white;
    border-color: #30476C;
    margin-left: 26px;
    margin-top: 26px;
    margin-bottom: 15px;
    border-radius: 40px;
    width: 140px;
    height: 75px;
}



.restartButton:active{
    color:white;
    background-color: #30476C;
}



#saveBtn {}